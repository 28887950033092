<template>
  <v-form @submit.prevent="$emit('submit')">
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.name"
      slim
      name="name">
      <v-text-field
        v-model="zone.name"
        :label="$t('attributes.zones.name')"
        name="name"
        :error-messages="errors"
        type="text">
      </v-text-field>
    </validation-provider>
  </v-form>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "ZoneForm",
  components: {
    ValidationProvider
  },
  props: {
    zone: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    }
  }
};
</script>
