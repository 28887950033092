<template>
  <v-row
    align="center"
    justify="center">
    <v-col md="8">
      <v-card>
        <v-card-title>
          {{
            $t("crudActions." + (id !== null
              ? "updateModel"
              : "createModel"), { model: $t("models.zones.single") })
          }}
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="validationObserver"
            slim>
            <zone-form
              :zone="zone"
              :validations="validationRules"
              @submit="createOrUpdateZone" />
          </validation-observer>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="$router.push({ name: $routes.zone.indexRoute.name })">
              {{ $t("actions.back") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              @click="createOrUpdateZone">
              {{
                $t("crudActions." + (id !== null
                  ? "update"
                  : "create"))
              }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createZone, fetchZone, generalUpdateZone } from "@/api/zone.js";
import { resetData } from "../../utils/ComponentHelper";
import ZoneForm from "../../components/zone/ZoneForm.vue";
import ContentLoader from "../../mixins/ContentLoader.js";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";

function data() {
  return {
    zone: {
      name: null,
    },
  };
};

export default {
  name: "ZoneCreateOrUpdate",
  components: {
    ZoneForm,
    ValidationObserver,
  },
  mixins: [ContentLoader, Validatable],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data,
  computed: {
    validationRules() {
      return {
        name: "required",
      };
    },
  },
  watch: {
    '$route' () {
      this.loadFormData();
    }
  },
  created() {
    this.loadFormData();
  },
  methods: {
    async createOrUpdateZone() {
      let zonePromise;

      const { validationObserver } = this.$refs,
        isValid = await validationObserver.validate();

      if (isValid) {
        if (typeof this.id === "number") {
          zonePromise = this.generalUpdateZone(this.id, this.zone);
        } else {
          zonePromise = createZone(this.zone);
        }

        zonePromise.then(() => {
          this.$router.push({ name: this.$routes.zone.indexRoute.name });
        }).
          catch(error => this.handleErrorResponse(validationObserver, error));
      }
    },
    async loadFormData() {
      resetData(this.$data, data);
      this.$startLoading();

      if (typeof this.id === "number") {
        try {
          const response = await fetchZone(this.id);

          this.zone = response.data.zone;
          await this.$nextTick();
        } catch (e) {
          console.error(e);
        }
      }
      this.$finishedLoading();
    },
    generalUpdateZone
  },
};
</script>
